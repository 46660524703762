<template>
  <div class="gallery-page">
    <v-row justify="center">
      <v-col md="8">
        <intract-smart-list
          class="mt-2 mx-2"
          paginated
          :endpoint="galleryEndpoint"
          disable-search
          no-more-data-text=""
          @updateAllItemsList="(l) => (galleryPosts = l)"
          ref="gallery-smart-list"
        >
          <template v-slot:list="{ itemsList: posts }">
            <v-row class="mt-2 mb-2" dense>
              <v-col cols="4" md="3" v-for="post in posts" :key="post.id">
                <v-img
                  class="cursor-pointer"
                  v-if="post.image_file"
                  :src="post.image_file.document"
                  aspect-ratio="1"
                  @click="
                    $router.push({
                      name: 'GalleryPost',
                      params: { postId: post.id },
                    })
                  "
                >
                </v-img>
                <div class="video-container" v-else-if="post.video_file">
                  <v-img
                    class="cursor-pointer"
                    src="https://www.pngkit.com/png/full/267-2678423_bacteria-video-thumbnail-default.png"
                    aspect-ratio="1"
                    cover
                    @click="
                      $router.push({
                        name: 'GalleryPost',
                        params: { postId: post.id },
                      })
                    "
                    ><div class="image-icon">
                      <v-btn icon
                        ><v-icon size="80px" color="white"
                          >mdi-play</v-icon
                        ></v-btn
                      >
                    </div>
                  </v-img>
                </div>
                <div class="video-container" v-else-if="post.video_link">
                  <v-img
                    class="cursor-pointer"
                    :src="Helper.getYouTubeThumbnailURL(post.video_link)"
                    aspect-ratio="1"
                    cover
                    @click="
                      $router.push({
                        name: 'GalleryPost',
                        params: { postId: post.id },
                      })
                    "
                    ><div class="image-icon">
                      <v-btn icon
                        ><v-icon size="80px" color="white"
                          >mdi-play</v-icon
                        ></v-btn
                      >
                    </div>
                  </v-img>
                </div>
                <div
                  class="folder-container fill-height"
                  v-else-if="post.is_folder"
                >
                  <v-card class="fill-height py-0" @click="$router.push({name: 'GalleryFolder', params: {folderId : post.id}})">
                    <v-card-text class="py-0 d-flex justify-center align-center fill-height" >

                        <div class="text-center">
                          <v-icon size="5em">mdi-folder</v-icon>
                          <p class="ma-0 mt-1 font-weight-medium folder-title">{{ post.caption }}</p>
                        </div>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
            </v-row>
          </template>
        </intract-smart-list>
      </v-col>
    </v-row>
    <!-- creation options -->
    <intract-list-sheet
      title="What do you want to post?"
      :visible="showPostOptions"
      :list="postOptions"
      @close="showPostOptions = false"
    />
    <!-- create image post -->
    <intract-create-edit-sheet
      persistent
      key="gallery-create-image-sheet"
      class="gallery-create-image-sheet"
      title="Create Image Post(s)"
      description="Upload image(s) and display in gallery"
      :visible="createImagePostSheet.visible"
      @close="
        createImagePostSheet.visible = false;
        createImagePostSheet.editId = null;
      "
      :fields="createImagePostFormFields"
      :file-size-limit="5242880"
      :endpoint="endpoints.galleryPostViewSet"
      :edit-id="createImagePostSheet.editId"
      :data-object="createImagePostSheet.obj"
      :custom-error-text="customImagePostValidation"
      :create-success-message="createImagePostSheet.createSuccessMessage"
      enable-media
      @objectCreated="imageCreated"
      @updateObject="(obj) => (createImagePostSheet.obj = obj)"
    />
    <!-- create video post -->
    <intract-create-edit-sheet
      persistent
      key="gallery-create-video-sheet"
      class="gallery-create-video-sheet"
      title="Create Video Post"
      description="Upload video content to the gallery"
      :visible="createVideoPostSheet.visible"
      @close="
        createVideoPostSheet.visible = false;
        createVideoPostSheet.editId = null;
      "
      :fields="createVideoPostFormFields"
      :endpoint="endpoints.galleryPostViewSet"
      :edit-id="createVideoPostSheet.editId"
      :data-object="createVideoPostSheet.obj"
      :custom-error-text="customVideoPostValidationError"
      :create-success-message="createVideoPostSheet.createSuccessMessage"
      enable-media
      @objectCreated="imageCreated"
      @updateObject="(obj) => (createVideoPostSheet.obj = obj)"
    />
    <!-- create folder -->
    <intract-create-edit-sheet
      persistent
      key="gallery-create-folder-sheet"
      class="gallery-create-folder-sheet"
      title="Create Folder"
      description="Create a folder to better organise your gallery"
      :visible="createFolderPostSheet.visible"
      @close="
        createFolderPostSheet.visible = false;
        createFolderPostSheet.editId = null;
      "
      :fields="createFolderFormFields"
      :endpoint="endpoints.galleryPostViewSet"
      :edit-id="createFolderPostSheet.editId"
      :data-object="createFolderPostSheet.obj"
      :create-success-message="createFolderPostSheet.createSuccessMessage"
      @objectCreated="folderCreated"
      @updateObject="(obj) => (createFolderPostSheet.obj = obj)"
    />
    <!-- create gallery post button -->
    <v-fab-transition>
      <v-btn
        color="primary"
        v-if="!isLoading && currentUser.is_admin"
        @click="showPostOptions = true"
        dark
        right
        bottom
        fixed
        fab
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>
<script>
import Mixins from "@utils/mixins";
import IntractSmartList from "@components/generics/IntractSmartList";
import IntractListSheet from "@components/generics/IntractListSheet";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";
import EventBus from "@utils/event_bus";

export default {
  name: "Gallery",
  mixins: [Mixins.essentials, Mixins.pullToRefresh],
  components: {
    IntractSmartList,
    IntractListSheet,
    IntractCreateEditSheet,
  },
  data() {
    return {
      showPostOptions: false,
      galleryPosts: [],
      postOptions: [
        {
          icon: "mdi-image",
          title: "Image(s)",
          subtitle: "Upload image(s) and display in gallery",
          action: () => (this.createImagePostSheet.visible = true),
        },
        {
          icon: "mdi-video",
          title: "Video(s)",
          subtitle: "Upload video content to the gallery",
          action: () => (this.createVideoPostSheet.visible = true),
        },
        {
          icon: "mdi-folder-multiple-image",
          title: "Folder",
          subtitle: "Create a folder to better organise your gallery",
          action: () => (this.createFolderPostSheet.visible = true),
        },
      ],

      createImagePostSheet: {
        visible: false,
        createSuccessMessage: "Image(s) uploaded successfully!",
        editId: null,
        obj: {
          created_by: null,
          institution: null,
          is_folder: false,
        },
      },
      createVideoPostSheet: {
        visible: false,
        createSuccessMessage: "Video(s) uploaded successfully!",
        editId: null,
        obj: {
          created_by: null,
          institution: null,
          is_folder: false,
        },
      },
      createFolderPostSheet: {
        visible: false,
        createSuccessMessage: "Folder created successfully!",
        editId: null,
        obj: {
          created_by: null,
          institution: null,
          is_folder: true,
        },
      },
    };
  },
  computed: {
    galleryEndpoint() {
      return this.Helper.addUrlParams(this.endpoints.galleryPostViewSet, [
        "ordering=-created", 
        "parent__isnull=true"
      ]);
    },
    customImagePostValidation() {
      
      if (!this.createImagePostSheet.obj.images_list || this.createImagePostSheet.obj.images_list.length <=10) return null;
      return "A maximum of 10 images can be uploaded!"
    },
    createImagePostFormFields() {
      return {
        caption: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Caption",
          required: false,
          md: 12,
          max: 500,
        },
        images_list: {
          fieldType: CreateEditFieldTypes.IMAGEUPLOAD,
          multiple: true,
          label: "Image(s)",
          helper: "JPG, PNG, GIF, etc",
          valid: () => this.createImagePostSheet.obj.images_list && this.createImagePostSheet.obj.images_list.length,
        },
      };
    },
    videosAndLinkPresent(){
      var videos = this.createVideoPostSheet.obj.videos_list != null && this.createVideoPostSheet.obj.videos_list.length > 0;
      var link = this.createVideoPostSheet.obj.video_link != null;
      return {videos, link};
    },
    customVideoPostValidationError(){
      if (this.videosAndLinkPresent.videos && this.videosAndLinkPresent.link) 
        return "Video Link and Video File(s) should be uploaded separately!";
      else if (this.createVideoPostSheet.obj.videos_list && this.createVideoPostSheet.obj.videos_list.length > 5)
        return "A maximum of 5 videos can be uploaded!"
      else 
        return null;
    },
    videoPostValidation(){
      return this.videosAndLinkPresent.videos != this.videosAndLinkPresent.link;
    },
    createVideoPostFormFields() {
      return {
        caption: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Caption",
          required: false,
          md: 12,
          max: 500,
        },
        video_link: {
          fieldType: CreateEditFieldTypes.LINKUPLOAD,
          multiple: false,
          label: "Link a video",
          helper: "Link a YouTube Video",
          valid: () => this.videoPostValidation,
        },
        videos_list: {
          fieldType: CreateEditFieldTypes.VIDEOUPLOAD,
          multiple: true,
          label: "Upload Video(s)",
          helper: "Upload video(s) from this device",
          valid: () => this.videoPostValidation,
        },
      };
    },
    createFolderFormFields() {
      return {
        caption: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Folder Title",
          required: true,
          counter: 40,
          max: 40,
          md: 12,
        },
      };
    },
  },
  methods: {
    async imageCreated(image) {
      this.onRefresh();
      this.$router.push({ name: "GalleryPost", params: { postId: image.id } });
    },
    async folderCreated(folder) {
      if(this.$refs['gallery-smart-list'])
        this.$refs["gallery-smart-list"].addItem(folder, true)
      this.$router.push({
        name: "GalleryFolder",
        params: { folderId: folder.id },
      });
    },
    setListeners() {
      EventBus.$on("gallery__post_deleted", (itemId) =>{
      if(this.$refs['gallery-smart-list'])
        this.$refs["gallery-smart-list"].removeItem(itemId)
      });
      EventBus.$on("gallery__post_updated", (item) => {
      if(this.$refs['gallery-smart-list'])

        this.$refs["gallery-smart-list"].replaceItem(item.id, item)
    });
    },
    async onRefresh() {
      if (this.$refs["gallery-smart-list"]) {
        console.log("GOING INSIDE");
        this.$refs["gallery-smart-list"].getItems();
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == "Home") {
      EventBus.$emit("keep_alive__destroy_view", "Gallery");
    }
    next();
  },
  created() {
    this.setListeners();
    this.createImagePostSheet.obj.created_by =
      this.createVideoPostSheet.obj.created_by =
      this.createFolderPostSheet.obj.created_by =
        this.currentUser.id;
    this.createImagePostSheet.obj.institution =
      this.createVideoPostSheet.obj.institution =
      this.createFolderPostSheet.obj.institution =
        this.currentInstitution.id;
  },
};
</script>
<style lang="sass" scoped>
.image-icon
  height: 100%
  width: 100%
  display: flex
  justify-content: center
  align-items: center

.folder-title
  display: -webkit-box
  -webkit-line-clamp: 2
  -webkit-box-orient: vertical
  overflow: hidden
  text-overflow: ellipsis
  max-width: 9em
</style>